import { AuthenticationService, SpinnerService } from '@sgprev-services';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'sgprev-ui';
    public hasInited = false;

    constructor(
        private authService: AuthenticationService,
        public spinnerService: SpinnerService,
        private config: PrimeNGConfig) { }

    public get isLoadingSpinnerVisible(): BehaviorSubject<boolean> {
        return this.spinnerService.isLoaging;
    }

    ngOnInit(): void {
        this.loadTokenFromParameters();
        console.log('TOKEN', this.authService.token);

        this.config.setTranslation({
            dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
            monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            today: 'Hoje',
            clear: 'Limpar',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Semana'
        });
    }

    private loadTokenFromParameters(): void {
        const urlParams = new URLSearchParams(window.location.search);
        this.authService.token = urlParams.get('token');
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.hasInited = true;
        }, 300);
    }
}
